import React, {useState, useEffect} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Container, Paper } from '@material-ui/core';
import { Assets } from 'enums';
import { Alert, Modal, Button, Spinner, Table, } from 'react-bootstrap';
import { PageStatus } from 'enums';
import { Show } from 'Layout';
import * as _ from "lodash";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';
import {AuthAPI, RedemptionModeAPI} from "../../API";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from "react-redux";
import GridContainer from "../Grid/GridContainer";
import CardHeader from "../Card/CardHeader";


function PreScreen() {

const { userId, role } = useSelector((state : any) => state.adminUser.adminUser);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        marginBottom: theme.spacing(3),
    },
    content: {
        padding: theme.spacing(3),
        textAlign: 'left',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        width: '100%',
        maxWidth: '400px', // Adjust the maximum width as needed
    },
    grow: {
        flexGrow: 1,
    },
}));

const MODAL_TYPES = {
    NONE: 'NONE',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    DETAILS: 'DETAILS',
    QUESTION: 'QUESTION'
  };

const [formType, setFormType] = useState(MODAL_TYPES.NONE);
const [status, setStatus] = useState(PageStatus.None);
const [error, setError] = useState(null);
const [formState, setFormState] = useState({});
const [preScreenApiData, setPreScreenApiData] = useState([]);
const [pageSize, setpageSize] = useState(10);
const [currentPageNumber, setCurrentPageNumber] = useState(1);
// const [totalResponseData, setTotalResponseData] = useState('');
// const [totalResponsePages, setTotalResponsePages] = useState('');
const [modalHeader, setModalHeader] = useState("Add");
const [id, setId] = useState('');

const handleOpenCreateModal = () => { setFormType(MODAL_TYPES.CREATE); setModalHeader("Add"); setId(''); };

const fetchList = async () => {
  setStatus(PageStatus.Loading);
  try {
    const responseData : any = await AuthAPI.getSurveyQuestions();
    console.log("responseData = ",responseData);
    
    setPreScreenApiData(responseData);
    // setTotalResponseData(responseData.totalCount);
    // setTotalResponsePages(responseData.totalPage);
    
    setStatus(PageStatus.Loaded);
  } catch (err) {
    setError("Failed, Try again!");
  }
};

const onCloseModalFunc = () => { setFormType(MODAL_TYPES.NONE); setFormState({}); setError(null); setStatus(PageStatus.None);};

const onFormSubmit = async (event)=>{
  event.preventDefault();
  try {
    let submitingData = {"details" : [{...formState}]};
    let apiRespo : any;
    console.log("payload = ",submitingData);
    
    if (id) {
      apiRespo = await AuthAPI.updatePreScreenQuestions({id, ...formState});
    }else{
      apiRespo = await AuthAPI.addPreScreenQuestions(submitingData);
    }
    setStatus(PageStatus.Submitting);
    const responseData : any = apiRespo;
    setStatus(PageStatus.Submitted);
    setFormType(MODAL_TYPES.NONE);
    setFormState({});
    fetchList();
  } catch (err) {    
    setError("Failed, Try again!");
    setStatus(PageStatus.Error);
  }
}

const handleChange = (e) => {
  let {name, value} = e.target;
  setFormState((prev)=>({
      ...prev, [name] : value
  }));
};

const updatePreScreenQuesion = (e, elem)=>{
  e.stopPropagation();
  setId(elem.id);
  setFormType(MODAL_TYPES.CREATE);
  setModalHeader("Update");
  setFormState({"question": elem.question, "answer": elem.answer});
}

// useEffect(() => {  fetchList(); }, [pageSize, currentPageNumber]);
useEffect(() => {  fetchList(); }, []);

const handlePageSizeChange = (newValue : any) => { setpageSize(newValue); setCurrentPageNumber(1); setTotalResponsePages(''); };
const handlePageNumberChange = (newValue : any) => { setCurrentPageNumber(newValue); };

  const classes = useStyles();
  
  return (
      <>  
        <div className={classes.root}>
          <Modal
            centered
            size="lg"
            backdrop="static"
            onHide={onCloseModalFunc}
            show = {formType == MODAL_TYPES.CREATE}
            style={{ zIndex: 1201 }}
          >
            <Modal.Header closeButton>
              <Modal.Title>  {modalHeader} PreScreen Question </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
              <Show when={status === PageStatus.Loading}>
                <div className="d-flex justify-content-center w-100 p-5">
                  <Spinner animation="border" variant="primary" />
                </div>
              </Show>
              <Alert variant="danger" show={status === PageStatus.Error}>  {error}  </Alert>
                      <form  onSubmit={onFormSubmit} >
                        <div className="row mt-2">
                            <div className="col">
                            <label htmlFor="titleEng">  Question*  </label>
                            <input
                                className="form-control"
                                name = "question"
                                onChange={handleChange}
                                value={formState.question || ""}
                                placeholder="Enter question"
                                required
                            />
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col">
                                <label htmlFor="body">Answer*</label>
                                <select 
                                  className="form-control"
                                  name = "answer"
                                  onChange={handleChange}
                                  value={formState.answer || ""}
                                  placeholder="Enter answer"
                                  required
                                >
                                  <option value="">Select answer</option>
                                  <option value="yes">Yes</option>
                                  <option value="no">No</option>
                                  <option value="manual">Manual</option>
                                </select>
                                {/* {errorBody && <div style={{ color: 'red' }}>{errorBody}</div>} */}
                            </div>
                        </div>

                        <Alert variant="danger" show={status === PageStatus.Error} className="mt-2">
                            {error}
                        </Alert>

                        <div className="d-flex align-items-center mt-2">
                            <button
                              type="submit"
                              className="btn btn-primary mr-3"
                            >
                              Submit
                            </button>

                            <Show when={status === PageStatus.Submitting}>
                                <Spinner animation="border" variant="primary" />
                            </Show>
                        </div>
                    </form>
            </Modal.Body>
          </Modal>


          {/* <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <Typography variant="h6">PreScreen Questions</Typography>
                <div className={classes.grow} />
                { (role  && (role == "admin" || role == "sub-admin") ? <Typography variant="h6">
                <Button
                    onClick={handleOpenCreateModal}
                      variant="primary"
                      size="sm"
                      className="mx-1"
                  >
                    <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" />
                    Create
                  </Button>
                </Typography> : "")}
            </Toolbar>
          </AppBar> */}

          <GridContainer>
            <div className='w-100 mb-3'>
              <CardHeader color="primary">
                <div className="d-flex align-items-center justify-content-between">
                <h4>PreScreen Questions</h4>
                <div>
                  <Button
                    onClick={handleOpenCreateModal}
                    variant="primary"
                    size="sm"
                    className="mx-1"
                  >
                    <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" />
                    Create
                  </Button>
                </div>
                </div>
              </CardHeader>
            </div>
        </GridContainer>


          <div className="jumbotron bg-white p-3 border shadow-sm">
            {/* <div className='mb-2 mt-3'>  
              <ShowDataAccording2PageSize 
                currentPageSize={pageSize} 
                currentPage={currentPageNumber}
                sendPageSize={handlePageSizeChange}
                showTotalData={totalResponseData} 
                sendLastPage={totalResponsePages}
              />
            </div> */}
            <div>
              {preScreenApiData.map((preScreen, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{preScreen.question} &nbsp; 
                      {role && (role == "admin" || role == "sub-admin") ? (
                        <i
                          className='fa fa-edit text-success'
                          onClick={(event) => updatePreScreenQuesion(event, preScreen)}
                          title="Edit details"
                        ></i>
                      ) : null}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div dangerouslySetInnerHTML={{ __html: preScreen.answer }} />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
            {/* <div>
             {totalResponsePages && <PaginationNumbering 
                currentPage={currentPageNumber} 
                returnPagenumber={handlePageNumberChange}
                showTotalData={totalResponseData} 
                sendLastPage={totalResponsePages}
              />}
            </div> */}
          </div>
        </div>
      </>
    );
  }

  export default PreScreen;
