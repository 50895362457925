import React from 'react';
import { reduxForm, } from 'redux-form';
import { Alert, Modal, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Show } from 'Layout';
import { PageStatus } from 'enums';
import { MasterDataAPI, SurveysAPI } from "../../API";
import Select from 'react-select';
import moment from "moment";
import Swal from 'sweetalert2';
export type FormValue = {
  name: string,
};

type State = {
  status: string,
  error: string | null,
  data: any,
  name: string,
};


function replacePlaceholderWithAnchor(text) {
  if (/<a\s+(?:[^>]*?\s+)?href=(?:'([^']*)'|"([^"]*)")/.test(text)) {
    return text;
  }
  const regex = /<([^>]+)>/;
  const match = text.match(regex);
  if (match) {
    const placeholder = match[0];
    const url = match[1];
    const anchorTag = `<a href='${url}' target='_blank' style='color: orange;'>${url}</a>`;
    return text.replace(placeholder, anchorTag);
  } else {
    return text;
  }
}

class Form extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      error: null,
      options: [],
      blacklistSurvey: [],
      data: '',
      name: "",
      company: "",
      description: "",
      "userLimitCommitted": 0,
      "userLimitCutoff": 0,
      "client": "",
      "ceggPoints": 0,
      "overquota": 0,
      "terminate": 0,
      "qualityterminate": 0,
      "surveyLength": 0,
      "publishDate": "",
      "expiryDate": "",
      "outlierCutoffTime": '',
      "costPerInterview": 0,
      "isActive": true,
      "companyLogo": "",
      "useUniqueLinks": false,
      "ipUnique": false,
      "surveyUrlIdentifier": "id",
      "url": "",
      "surveyType": "Open",
      "pointAllocationType": "Manual",
      disclaimer: 'Disclaimer: Dear Respondent, Thank you for your continued patronage. To serve you more Research Polls, we request that you always keep your profile up to date. Your valuable profile is a guiding light for the Online Market Research Industry, please visit <indiapolls.com> to login and update your profile for a more rewarding journey ahead ! Thank you.',
      isPaused: false,
      countries: [],
      selectedCountryOption: null,
      country: '',
      description_one: '',
      description_two: '',
      description_three: '',
      description_four: '',
      colorCode: '',
      whatsapp: "N",
      email: "N",
      sms: "N",
      clientir: "",
      // status: '',
      // data: null,
      userLimitCommitted_checkbox: '',
      IPollsQuotaLimit: 'N' 

    };
  }

  componentDidMount() {
    if (!!this.props.id) {
      this.fetchDetails();
    } else {
      this.fetchList()
    }
  }

  fetchDetails() {
    Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => {
        if (!this.props.id) {
          return Promise.reject(new Error('Invalid ID'));
        }

        return SurveysAPI.getOne(this.props.id);
      })
      .then((data) => {

        this.initializeValues(data);
        this.setState({
          data,
          status: PageStatus.Loaded,
        }, () => {
          this.fetchList()
          this.fetchCountryList()
        });
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message });
      });
  }

  // ====================add functionallity status chnage ======
  handleClick = (status) => {
    console.log(status);
    this.setState({ status }, () => {
      if (this.state.data) {
        this.handleClickcheck(this.state.data);
      }
    });
  };

  handleClickcheck = (data) => {
    const modals = document.getElementsByClassName('fade modal show');
    const changeExpireDateInput = document.getElementById('expiryDate');

    if (!data || !data.expiryDate) {
      return; // Exit early if data or expiryDate is not available
    }

    // Get and parse the new expiry date from the input field
    const changeExpireDate = changeExpireDateInput ? new Date(changeExpireDateInput.value) : null;
    const currentDate = new Date();

    // Function to set zIndex
    const setModalZIndex = (zIndex) => {
      Array.from(modals).forEach(modal => {
        modal.style.zIndex = zIndex;
      });
    };

    if (changeExpireDate && changeExpireDate < currentDate) {
      setModalZIndex('0');
      this.setState({ surveyType: 'Hold' }, () => {
        Swal.fire({
          icon: 'warning',
          title: 'Expired',
          text: 'The expiry date is in the past. Please correct the date',
        }).then(() => {
          setModalZIndex(''); // Reset zIndex after Swal.fire closes
        });
      });
    } else {
      setModalZIndex(''); // Ensure zIndex is reset for non-expired cases
      this.setState({ surveyType: 'Open' });
    }
  };





  onChangeStatus = (id, status) => {
  };

  fetchCountryList(): Promise<void> {
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => MasterDataAPI.countryList('10'))
      .then((countries) => {
        const options = countries.map(country => ({
          label: country.name,
          value: country.name
        }));
        options.sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });
        this.setState({ countries: options, status: PageStatus.Loaded });
        if (this.state.country) {
          const option = options.find(item => item.value === this.state.country);
          this.setState({ selectedCountryOption: option });
        } else {
          this.setState({ country: options[0].label, selectedCountryOption: options[0] });
        }
      })
      .catch((error) => {
        this.setState({ error: error.message, status: PageStatus.Error });
      });
  }


  fetchList(): Promise<void> {

    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => SurveysAPI.getAll(10000, 1, {}))
      .then((surveys) => {
        let responseData: any = surveys;
        const options = responseData.data.map(item => ({
          label: item.name,
          value: item.id
        }));
        this.setState({ options, status: PageStatus.Loaded });
        this.fetchCountryList()
        if (this.state.data.surveyblacklistentity) {
          const existing = options.find((item: any) => item.value === this.state.data.surveyblacklistentity.blacklistSurveyId);
          console.log('this.state.data---->', existing)
          if (existing) {
            this.setState({
              blacklistSurvey: [this.state.data.surveyblacklistentity.blacklistSurveyId],
              selectedSurveyOptions: [{
                "label": existing.label,
                "value": this.state.data.surveyblacklistentity.blacklistSurveyId
              }]
            })
          }
        }
      })
      .catch((error) => {
        this.setState({ error: error.message, status: PageStatus.Error });
      });
  }

  formValues() {
    return {
      name: this.state.name + " [" + this.state.terminate + " - " + this.state.ceggPoints + "]",
      company: this.state.company,
      description: this.state.description,
      "userLimitCommitted": this.state.userLimitCommitted,
      "userLimitCutoff": this.state.userLimitCutoff,
      "client": this.state.client,
      "ceggPoints": this.state.ceggPoints,
      "overquota": this.state.overquota,
      "terminate": this.state.terminate,
      "qualityterminate": this.state.qualityterminate,
      "surveyLength": this.state.surveyLength,
      "publishDate": this.state.publishDate,
      "expiryDate": this.state.expiryDate,
      "outlierCutoffTime": this.state.outlierCutoffTime,
      "costPerInterview": this.state.costPerInterview,
      "isActive": this.state.isActive,
      "companyLogo": this.state.companyLogo,
      "useUniqueLinks": this.state.useUniqueLinks,
      "ipUnique": this.state.ipUnique,
      "surveyUrlIdentifier": this.state.surveyUrlIdentifier,
      "url": this.state.url,
      blacklistSurvey: this.state.blacklistSurvey,
      "surveyType": this.state.surveyType,
      "pointAllocationType": this.state.pointAllocationType,
      minimumInterviewDuration: 20,
      isPaused: false,
      disclaimer: replacePlaceholderWithAnchor(this.state.disclaimer),
      description_one: this.state.description_one,
      description_two: this.state.description_two,
      description_three: this.state.description_three,
      description_four: this.state.description_four,
      country: this.state.country,
      colorcode: this.state.colorCode,
      sendServeyBy: this.state.sendServeyBy,
      whatsapp: this.state.whatsapp,
      email: this.state.email,
      sms: this.state.sms,
      clientir: this.state.clientir,
      IPollsQuotaLimit: this.state.IPollsQuotaLimit
    };
    this.handleClickcheck(this.state.data);
  }

  initializeValues(data) {
    console.log("update data = ", data);
    return this.setState({
      name: data.name.split(" [")[0],
      company: data.company,
      description: data.description,
      "userLimitCommitted": data.userLimitCommitted,
      "userLimitCutoff": data.userLimitCutoff,
      "client": data.client,
      "ceggPoints": data.ceggPoints,
      "overquota": data.overquota,
      "terminate": data.terminate,
      "qualityterminate": data.qualityterminate,
      "surveyLength": data.surveyLength,
      "publishDate": moment(data.publishDate).format("YYYY-MM-DD"),
      "expiryDate": moment(data.expiryDate).format("YYYY-MM-DD"),
      "outlierCutoffTime": data.outlierCutoffTime,
      "costPerInterview": data.costPerInterview,
      "isActive": data.isActive,
      "companyLogo": data.companyLogo,
      "useUniqueLinks": data.useUniqueLinks,
      "ipUnique": data.ipUnique,
      "surveyUrlIdentifier": data.surveyUrlIdentifier,
      "url": data.url,
      "surveyType": data.surveyType,
      "pointAllocationType": data.pointAllocationType,
      minimumInterviewDuration: data.minimumInterviewDuration,
      isPaused: data.isPaused,
      disclaimer: data.disclaimer,
      description_one: data.description_one,
      description_two: data.description_two,
      description_three: data.description_three,
      description_four: data.description_four,
      country: data.country,
      colorCode: data.colorcode,
      whatsapp: data.whatsapp,
      email: data.email,
      sms: data.sms,
      clientir: data.clientir,
      IPollsQuotaLimit:data.IPollsQuotaLimit
    });
  }
  onSubmit() {
    if (!this.props.id) {
      return this.create();
    }
    return this.update();
  }

  create() {
    const valuesIn = this.formValues();
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Submitting }))
      .then(() => SurveysAPI.create(valuesIn))
      .then((data) => {
        this.props.onSubmit(data.id);
        return this.setState({ status: PageStatus.Submitted });
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message });
      });
  }

  update() {
    const valuesIn = this.formValues();
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Submitting }))
      .then(() => SurveysAPI.update(this.props.id, valuesIn))
      .then(() => {
        this.setState({ status: PageStatus.Submitted });
        return this.props.onSubmit(this.props.id);
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message });
      });
  }

  reset() {
    return this.setState({
      data: '',
      name: "",
      company: "",
      description: "",
      "userLimitCommitted": 0,
      "userLimitCutoff": 0,
      "client": "",
      "ceggPoints": 0,
      "surveyLength": 0,
      "publishDate": "",
      "expiryDate": "",
      "outlierCutoffTime": '',
      "costPerInterview": 0,
      "isActive": true,
      "companyLogo": "",
      "useUniqueLinks": false,
      "ipUnique": false,
      "surveyUrlIdentifier": "id",
      "url": "",
      "surveyType": "Open",
      "pointAllocationType": "Manual",
      isPaused: false,
      selectedCountryOption: null,
      country: '',
      description_one: '',
      description_two: '',
      description_three: '',
      description_four: '',
      colorCode: '',
      whatsapp: "N",
      email: "N",
      sms: "N",
      clientir: ''
    });
  }

  handleChange = (selectedSurveyOptionss) => {
    this.setState({
      blacklistSurvey: selectedSurveyOptionss.map(option => option.value),
      selectedSurveyOptionss
    });
  };

  // handleChange = async (selectedSurveyOptions) => {
  //   this.setState({ blacklistSurvey: [selectedSurveyOptions.value], selectedSurveyOptions});
  // };

  handleCountryChange = async (selectedCountryOption) => {
    this.setState({ country: selectedCountryOption.label, selectedCountryOption });
  };

  handleSendSurvey = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked ? "Y" : "N"
    });
  }
  // ================================================
  handleUserLimitCommittedChange = (e) => {
    const isChecked = e.target.checked; // Use checked instead of value
    this.setState({
      userLimitCommitted_checkbox: isChecked, // Set to true or false
      IPollsQuotaLimit: true ? 'Y' : 'N' // Update IPollsQuotaLimit based on checked state
    });
  };
  //====================================================

  calculateCompletePoints = (e) => {
    const loiValue = parseInt(e.target.value, 10);
    if (loiValue > 0 && loiValue <= 5) { this.setState({ overquota: 0, terminate: 0, ceggPoints: 15 }); }
    else if (loiValue >= 6 && loiValue <= 10) { this.setState({ overquota: 2, terminate: 2, ceggPoints: 25 }); }
    else if (loiValue >= 11 && loiValue <= 15) { this.setState({ overquota: 2, terminate: 2, ceggPoints: 35 }); }
    else if (loiValue >= 16 && loiValue <= 20) { this.setState({ overquota: 2, terminate: 2, ceggPoints: 45 }); }
    else if (loiValue >= 21 && loiValue <= 25) { this.setState({ overquota: 2, terminate: 2, ceggPoints: 55 }); }
    else if (loiValue >= 26 && loiValue <= 30) { this.setState({ overquota: 2, terminate: 2, ceggPoints: 65 }); }
    else if (loiValue > 30) { this.setState({ overquota: 2, terminate: 2, ceggPoints: 100 }); }
    else { this.setState({ overquota: 0, terminate: 0, ceggPoints: 0 }); }
  }

  // ==============================================================
  render() {
    const isChecked = (name: any) => this.state[name] === "Y";
    const surveyDescriptionOption = ["Education", "Fashion and Accessories", "Financial Services", "Food", "Healthcare", "Gadgets", "Home Care", "Lifestyles and Hobbies", "Media and Entertainment", "Corporate Related", "Personal Care and Beauty", "Pet Care", "Shopping and Retail", "Technology and Computing", "Toys and Games", "Travel", "Exciting New", "Augment Survey"];
    return (
      <Modal
        centered
        size="xl"
        backdrop="static"
        onHide={this.props.onClose}
        show={this.props.show}
        style={{ zIndex: 1201 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Survey
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
          <Show when={this.state.status === PageStatus.Loading}>
            <div className="d-flex justify-content-center w-100 p-5">
              <Spinner animation="border" variant="primary" />
            </div>
          </Show>

          <Alert variant="danger" show={this.state.status === PageStatus.Error}>
            {this.state.error}
          </Alert>

          <form onSubmit={this.props.handleSubmit(
            (event) => this.onSubmit(),
          )}
          >

            <div className="row mt-2">
              <div className="col">
                <label htmlFor="name">Survey Name*</label>
                <input
                  className="form-control"
                  name="name"
                  onChange={(e) => this.setState({ name: e.target.value })}
                  value={this.state.name}
                  placeholder="Enter here"
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="company">Company*</label>
                <input
                  className="form-control"
                  id="company"
                  name="company"
                  onChange={(e) => this.setState({ company: e.target.value })}
                  value={this.state.company}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <label htmlFor="description">Description*</label>
                {/* <input
                      className="form-control"
                      name="description"
                      onChange={(e) => this.setState({ description: e.target.value })}
                      value={this.state.description}
                      placeholder="Enter here"
                      required
                  /> */}
                <select
                  name='description'
                  id='description'
                  onChange={(e) => { this.setState({ description: e.target.value }) }}
                  value={this.state.description}
                  required
                  style={{
                    width: '100%',
                    display: 'block',
                    height: '40px',
                    lineHeight: '1.5',
                    color: '#495057',
                    backgroundColor: '#fff',
                    backgroundClip: 'padding-box',
                    border: '1px solid #ced4da',
                    borderRadius: '5px',
                    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                  }}
                >
                  <option value=''>--Choose--</option>
                  {surveyDescriptionOption.length > 0 ? surveyDescriptionOption.map((item, index) => (
                    <option key={index} value={"Survey On " + item}>{item}</option>
                  )) : ''}
                </select>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col mt-2">
                <label htmlFor="description">Survey Type*</label><br />
                {/* <div className="custom-control custom-radio custom-control-inline">
                    <input
                        type="radio"
                        id="surveyType1"
                        name="surveyType1"
                        className="custom-control-input"
                        checked={this.state.surveyType === 'Open'}
                        onChange={() => this.setState({ surveyType: 'Open' })}
                    />
                    <label className="custom-control-label" htmlFor="surveyType1" >Open</label>
                  </div> */}

                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="surveyType3"
                    name="surveyType3"
                    checked={this.state.surveyType === 'Hold'}
                    className="custom-control-input"
                    onChange={() => this.setState({ surveyType: 'Hold' })}
                  />
                  <label className="custom-control-label" htmlFor="surveyType3">On Hold</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="surveyType1"
                    name="surveyType1"
                    className="custom-control-input"
                    checked={this.state.surveyType === 'Open'}
                    onChange={() => this.setState({ surveyType: 'Open' })}
                    onClick={() => this.handleClick('Open')}
                  />
                  <label className="custom-control-label" htmlFor="surveyType1">Live</label>
                </div>

                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="surveyType4"
                    name="surveyType4"
                    checked={this.state.surveyType === 'Wait'}
                    className="custom-control-input"
                    onChange={() => this.setState({ surveyType: 'Wait' })}
                  />
                  <label className="custom-control-label" htmlFor="surveyType4">Ids awaited </label>
                </div>



                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="surveyType2"
                    name="surveyType1"
                    checked={this.state.surveyType === 'Close'}
                    className="custom-control-input"
                    onChange={() => this.setState({ surveyType: 'Close' })}
                  />
                  <label className="custom-control-label" htmlFor="surveyType2">End</label>
                </div>
              </div>

              <div className="col mt-2">
                <label htmlFor="description">Point Allocation*</label><br />
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="pointAllocation1"
                    name="pointAllocation1"
                    className="custom-control-input"
                    checked={this.state.pointAllocationType === 'Manual'}
                    onChange={() => this.setState({ pointAllocationType: 'Manual' })}
                  />
                  <label className="custom-control-label" htmlFor="pointAllocation1" >Manual</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="pointAllocation2"
                    name="pointAllocation1"
                    checked={this.state.pointAllocationType === 'Auto'}
                    className="custom-control-input"
                    onChange={() => this.setState({ pointAllocationType: 'Auto' })}
                  />
                  <label className="custom-control-label" htmlFor="pointAllocation2">Auto</label>
                </div>
              </div>


                {/* ------------------------- */}
                <div className="col mt-2">
                <label htmlFor="userLimitCommitted_checkbox">Client Quota </label>
                <div className="col">
                    <input
                      type="checkbox"
                      id='userLimitCommitted_checkbox'
                      name="userLimitCommitted_checkbox"
                      onChange={this.handleUserLimitCommittedChange}
                      checked={this.state.userLimitCommitted_checkbox} // Use checked instead of value
                      
                    />
                  </div>
                  </div>
               {/* ------------------------- */}
              <div className="col mt-2">
                <label htmlFor="description">Send Survey*</label><br />
                <div className="custom-control custom-control-inline">
                  <input
                    type="checkbox"
                    id="email"
                    name="email"
                    className="custom-control-input"
                    checked={isChecked("email")}
                    onChange={this.handleSendSurvey}
                  />
                  <label className="custom-control-label" htmlFor="email" >Email</label>
                </div>
                <div className="custom-control custom-control-inline">
                  <input
                    type="checkbox"
                    id="sms"
                    name="sms"
                    checked={isChecked("sms")}
                    className="custom-control-input"
                    onChange={this.handleSendSurvey}
                  />
                  <label className="custom-control-label" htmlFor="sms">SMS</label>
                </div>
                <div className="custom-control custom-control-inline">
                  <input
                    type="checkbox"
                    id="whatsapp"
                    name="whatsapp"
                    checked={isChecked("whatsapp")}
                    className="custom-control-input"
                    onChange={this.handleSendSurvey}
                  />
                  <label className="custom-control-label" htmlFor="whatsapp">Whats App</label>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <label htmlFor="userLimitCommitted">Client Quota *</label>
                <input
                  type="number"
                  className="form-control"
                  name="userLimitCommitted"
                  onChange={(e) => this.setState({ userLimitCommitted: e.target.value })}
                  value={this.state.userLimitCommitted}
                  placeholder="Enter here"
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="userLimitCutoff">IPolls Quota *</label>
                <input
                  type="number"
                  className="form-control"
                  id="userLimitCutoff"
                  name="userLimitCutoff"
                  onChange={(e) => this.setState({ userLimitCutoff: e.target.value })}
                  value={this.state.userLimitCutoff}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="surveyLength">Survey Length(in minutes)*</label>
                <input
                  type="number"
                  className="form-control"
                  id="surveyLength"
                  name="surveyLength"
                  onChange={(e) => this.setState({ surveyLength: e.target.value }, () => { this.calculateCompletePoints(e) })}
                  value={this.state.surveyLength}
                  placeholder="Enter here"
                  required
                />
              </div>
              {/* <div className="col">
                <label htmlFor="client">Client*</label>
                <input
                  className="form-control"
                  name="client"
                  onChange={(e) => this.setState({ client: e.target.value })}
                  value={this.state.client}
                  placeholder="Enter here"
                  required
                />
              </div> */}
              <div className="col">
                <label htmlFor="ceggPoints">Complete Points*</label>
                <input
                  type="number"
                  className="form-control"
                  id="ceggPoints"
                  name="ceggPoints"
                  onChange={(e) => { this.setState({ ceggPoints: e.target.value }, this.calculateIr); }}
                  value={this.state.ceggPoints}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <label htmlFor="overquota">Over Quota Points*</label>
                <input
                  type="number"
                  className="form-control"
                  id="overquota"
                  name="overquota"
                  onChange={(e) => this.setState({ overquota: e.target.value })}
                  value={this.state.overquota}
                  placeholder="Enter here"
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="ceggPoints">Terminate Points*</label>
                <input
                  type="number"
                  className="form-control"
                  id="terminate"
                  name="terminate"
                  onChange={(e) => { this.setState({ terminate: e.target.value }, this.calculateIr); }}
                  value={this.state.terminate}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <label htmlFor="qualityterminate">IR*</label>
                <input
                  type="number"
                  className="form-control"
                  id="clientir"
                  name="clientir"
                  onChange={(e) => this.setState({ clientir: e.target.value })}
                  value={this.state.clientir}
                  placeholder="Enter here"
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="qualityterminate">Quality Terminate Points*</label>
                <input
                  type="number"
                  className="form-control"
                  id="qualityterminate"
                  name="qualityterminate"
                  onChange={(e) => this.setState({ qualityterminate: e.target.value })}
                  value={this.state.qualityterminate}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>


            <div className="row mt-2">
              <div className="col">
                <label htmlFor="companyLogo">Logo</label>
                <input
                  className="form-control"
                  name="companyLogo"
                  onChange={(e) => this.setState({ companyLogo: e.target.value })}
                  value={this.state.companyLogo}
                  placeholder="Enter here"
                />
              </div>
              {/* <div className="col">
                <label htmlFor="surveyLength">Survey Length(in minutes)*</label>
                <input
                  type="number"
                  className="form-control"
                  id="surveyLength"
                  name="surveyLength"
                  onChange={(e) => this.setState({ surveyLength: e.target.value }, ()=>{this.calculateCompletePoints(e)})}
                  value={this.state.surveyLength}
                  placeholder="Enter here"
                  required
                />
              </div> */}
              <div className="col">
                <label htmlFor="client">Client*</label>
                <input
                  className="form-control"
                  name="client"
                  onChange={(e) => this.setState({ client: e.target.value })}
                  value={this.state.client}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="text">Start Date*</label>
                <input
                  type="date"
                  className="form-control"
                  name="publishDate"
                  onChange={(e) => this.setState({ publishDate: e.target.value })}
                  value={this.state.publishDate}
                  placeholder="Enter here"
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="expiryDate">End Date*</label>
                <input
                  type="date"
                  className="form-control"
                  id="expiryDate"
                  name="expiryDate"
                  onChange={(e) => this.setState({ expiryDate: e.target.value })}
                  value={this.state.expiryDate}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="costPerInterview">Cost Per Interview (INR)*</label>
                <input
                  type="number"
                  className="form-control"
                  name="costPerInterview"
                  onChange={(e) => this.setState({ costPerInterview: e.target.value })}
                  value={this.state.costPerInterview}
                  placeholder="Enter here"
                  required
                />
              </div>
              <div className="col">
                <label htmlFor="outlierCutoffTime">Speeder Limit (In minutes)*</label>
                <input
                  type="number"
                  className="form-control"
                  id="outlierCutoffTime"
                  name="outlierCutoffTime"
                  onChange={(e) => this.setState({ outlierCutoffTime: e.target.value })}
                  value={this.state.outlierCutoffTime}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="text">BlackList Surveys</label>
                <Select
                  name='state'
                  id='state'
                  onChange={this.handleChange}
                  value={this.state.selectedSurveyOptionss}
                  isMulti
                  required
                  options={this.state.options}
                />
                {/* <Select
                  name='state'
                  id='state'
                  onChange={this.handleChange}
                  value={this.state.selectedSurveyOptions}
                  required
                  options={this.state.options}
                /> */}
              </div>
              <div className="col">
                <label htmlFor="surveyUrlIdentifier">Survey Url Identifier*</label>
                <input
                  className="form-control"
                  id="surveyUrlIdentifier"
                  name="surveyUrlIdentifier"
                  onChange={(e) => this.setState({ surveyUrlIdentifier: e.target.value })}
                  value={this.state.surveyUrlIdentifier}
                  placeholder="Enter here"
                  required
                />
              </div>
            </div>

            <div className="row ml-3 mt-2">
              <div className="col">
                <input
                  type="checkbox"
                  id="ipUnique"
                  name="ipUnique"
                  className="custom-control-input"
                  checked={this.state.ipUnique}
                  onChange={() => this.setState({ ipUnique: !this.state.ipUnique })}
                />
                <label className="custom-control-label" htmlFor="ipUnique" >Use Unique IPs</label>
              </div>
              <div className="col">
                <input
                  type="checkbox"
                  id="useUniqueLinks"
                  name="useUniqueLinks"
                  className="custom-control-input"
                  checked={this.state.useUniqueLinks}
                  onChange={() => this.setState({ useUniqueLinks: !this.state.useUniqueLinks })}
                />
                <label className="custom-control-label" htmlFor="useUniqueLinks">Use Unique Links</label>
              </div>
            </div>

            <Show when={this.state.useUniqueLinks === false}>
              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="url">Survey URL*</label>
                  <input
                    className="form-control"
                    name="url"
                    onChange={(e) => this.setState({ url: e.target.value })}
                    value={this.state.url}
                    placeholder="Enter here"
                    required
                  />
                </div>
              </div>
            </Show>

            <div className="row mt-2">
              <div className="col">
                <label htmlFor="url">Disclaimer</label>
                <textarea
                  className="form-control"
                  style={{ height: '180px' }}
                  onChange={(e) => this.setState({ disclaimer: e.target.value })}
                  value={this.state.disclaimer}
                  placeholder="Please enter"
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label htmlFor='country'>Country*</label>
                <Select
                  name='countryTitle'
                  id='countryTitle'
                  onChange={this.handleCountryChange}
                  value={this.state.selectedCountryOption}
                  required
                  options={this.state.countries}
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col">
                <label htmlFor="description">Description One</label>
                <input
                  className="form-control"
                  name="description"
                  onChange={(e) => this.setState({ description_one: e.target.value })}
                  value={this.state.description_one}
                  placeholder="Enter here"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="description">Description Two</label>
                <input
                  className="form-control"
                  name="description"
                  onChange={(e) => this.setState({ description_two: e.target.value })}
                  value={this.state.description_two}
                  placeholder="Enter here"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="description">Description Three</label>
                <input
                  className="form-control"
                  name="description"
                  onChange={(e) => this.setState({ description_three: e.target.value })}
                  value={this.state.description_three}
                  placeholder="Enter here"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="description">Description Four</label>
                <input
                  className="form-control"
                  name="description"
                  onChange={(e) => this.setState({ description_four: e.target.value })}
                  value={this.state.description_four}
                  placeholder="Enter here"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="description">Color Code</label>
                <input
                  className="form-control"
                  name="description"
                  onChange={(e) => this.setState({ colorCode: e.target.value })}
                  value={this.state.colorCode}
                  placeholder="Enter here"
                />
              </div>
            </div>

            <hr />

            <Alert variant="danger" show={!!this.state.error} className="mt-2">
              {this.state.error}
            </Alert>

            <div className="d-flex align-items-center mt-2">
              <button
                type="submit"
                disabled={!this.state.name}
                className="btn btn-primary mr-3"
              >
                Submit
              </button>

              <button
                type="button"
                disabled={false}
                onClick={() => this.reset()}
                className="btn btn-light mr-3"
              >
                Reset
              </button>

              <Show when={this.state.status === PageStatus.Submitting}>
                <Spinner animation="border" variant="primary" />
              </Show>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

const dataFormRedux = reduxForm<FormValue, any>({
  form: 'dataForm',
})(Form);


const dataFormWithRouter = withRouter(dataFormRedux);

export { dataFormWithRouter as Form };
